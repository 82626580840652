import { useParams } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from '../helpers/Url';
import Api from '../helpers/Api';
import PackList from '../components/follow/PackList';
import { PackTrackingInfo } from '../interfaces/PackInfo';
import TrackingInfo from '../components/follow/TrackingInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

type FormErrors = {
  code?: string
}

const Follow = () => {
  const { t } = useTranslation('common');
  const { param2, param3 } = useParams();
  const code: string = param3 ? param3 : param2 ? param2 : '';
  const [packCode, setPackCode] = useState(code);
  const [trackingInfo, setTrackingInfo] = useState<PackTrackingInfo[]>([]);
  const [packList, setPackList] = useState();

  useEffect(() => {
    if (packCode) {
      Api('shipments?barcode=' + packCode).then((res) => {
        setTrackingInfo(res['data']);
      });
    } else if (sessionStorage.userToken) {
      Api('shipments', undefined, 1, true).then((res) => {
        if (res) {
          setPackList(res['data']);
        }
      });
    }

  }, [packCode]);

  if (code !== packCode) {
    return <Redirect href={'/follow/' + packCode} />;
  }

  return (<>
    <h3>{t('package_tracking')}</h3>

    <Formik
      initialValues={{
        code: code
      }}
      validate={values => {
        const errors: FormErrors = {};

        if (!values.code) {
          errors.code = t('errors.required');
        } else if (values.code.length < 6) {
          errors.code = t('errors.invalid_pack_code');
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setPackCode(values.code);
        setSubmitting(false);
      }}
    >{({ isSubmitting }) => (<Form>
      <div className={'flex-row mobile-row'}>
        <label className={'col'}>
          <Field type={'text'} name={'code'} placeholder={' '} />
          <span>{t('package_tracking_code')}</span>
          <ErrorMessage name={'code'} component={'div'} className={'error'} />
        </label>

        <div className={'col-1-5'}>
          <button type={'submit'} className={'btn-primary wide'} disabled={isSubmitting}>{t('track_package')}</button>
        </div>
      </div>

    </Form>)}
    </Formik>

    <div className={'mv-8 center'}>
      {packCode ? (trackingInfo && trackingInfo.length > 0 ? (<TrackingInfo trackingInfo={trackingInfo} />) : (<>
        {t('errors.package_with_code_not_found')}
      </>)) : null}
    </div>

    {!packCode && sessionStorage.userToken && (
      (packList === undefined && <div className={'center'}><FontAwesomeIcon icon={faSpinnerThird} className={'spinner'} /></div>)
      || (packList && <PackList items={packList} />)
    )}
  </>);
};
export default Follow;