import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { faBoxCircleCheck, faMagnifyingGlass, faPersonCarryBox } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useEffect, useState } from 'react';
import AutoComplete from './AutoComplete';
import { AutoCompleteOption } from '../interfaces/AutoCompleteOption';
import Api from '../helpers/Api';
import { Redirect } from '../helpers/Url';
import { priceData } from '../interfaces/PriceData';
import etIcon from '../img/et.svg';
import ConfirmModal from './modals/ConfirmModal';
import IsMobile from '../helpers/IsMobile';

type FormErrors = {
  senderName?: string, senderEmail?: string, senderPhone?: string, consigneeEmail?: string, consigneePhone?: string, boxLocation?: string, agree?: string
}

const PackInfo = ({ provider, prices, size }: { provider: string, prices: priceData[], size: string }) => {
  const [options, setOptions] = useState<AutoCompleteOption[]>([]);
  const [boxLocation, setBoxLocation] = useState(0);
  const [boxText, setBoxText] = useState('');
  const [packCode, setPackCode] = useState('');
  const [confirmModal, setConfirmModal] = useState<ReactElement>();
  const isMobile = IsMobile();

  const { t } = useTranslation('common');

  useEffect(() => {
    Api('shipments/locations?provider=' + provider).then(res => {
      let optionsData: AutoCompleteOption[] = [];
      for (let row of res.data) {
        optionsData.push({
          value: row.locationId, text: row.name, group: row.city, extraText: row.address
        });
      }

      if (optionsData.toString() !== options.toString()) {
        setOptions(optionsData);
      }
    });
    let elem = document.getElementById('h3');
    if (elem && isMobile) {
      elem.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }

  }, [isMobile, options, provider]);

  if (packCode) {
    return <Redirect href={'/sent/' + packCode + '.' + provider}></Redirect>;
  }
  const errors: FormErrors = {};

  return (<>
    <h3 id={'h3'}>{t('enter_pack_details')}</h3>

    <Formik
      initialValues={{
        senderName: '',
        senderEmail: '',
        senderPhone: '',
        consigneeName: '',
        consigneeEmail: '',
        consigneePhone: '',
        locationId: 0,
        serviceId: 0,
        boxLocation: 0,
        agree: false,
        provider: provider,
        boxSize: size,
        boxName: boxText
      }}
      validate={values => {
        for (let key in errors) {
          // @ts-ignore
          delete errors[key];
        }

        if (!values.senderName) {
          errors.senderName = t('errors.required');
        }

        if (!values.senderEmail) {
          errors.senderEmail = t('errors.required');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.senderEmail)) {
          errors.senderEmail = t('errors.invalid_email');
        }

        values.senderPhone = formatPhone(values.senderPhone);

        if (!values.senderPhone) {
          errors.senderPhone = t('errors.required');
        } else if (!((values.senderPhone.substring(0, 1) === '8' && values.senderPhone.length === 8) || (values.senderPhone.substring(0, 1) === '5' && (values.senderPhone.length === 7 || values.senderPhone.length === 8)))) {
          errors.senderPhone = t('errors.invalid_phone');
        }

        if (!values.consigneeEmail) {
          errors.consigneeEmail = t('errors.required');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.consigneeEmail)) {
          errors.consigneeEmail = t('errors.invalid_email');
        }

        values.consigneePhone = formatPhone(values.consigneePhone);

        if (!values.consigneePhone) {
          errors.consigneePhone = t('errors.required');
        } else if (!((values.consigneePhone.substring(0, 1) === '8' && values.consigneePhone.length === 8) || (values.consigneePhone.substring(0, 1) === '5' && (values.consigneePhone.length === 7 || values.consigneePhone.length === 8)))) {
          errors.consigneePhone = t('errors.invalid_phone');
        }

        if (!boxLocation) {
          errors.boxLocation = t('errors.required');
        }

        if (!values.agree) {
          errors.agree = t('errors.agree_required');
        } else {
          delete errors.agree;
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        let dataToSend = values;
        dataToSend.provider = provider;
        dataToSend.boxName = boxText;
        dataToSend.agree = true;
        dataToSend.locationId = boxLocation;

        dataToSend.senderPhone = formatPhone(dataToSend.senderPhone);
        dataToSend.consigneePhone = formatPhone(dataToSend.consigneePhone);

        // @ts-ignore
        dataToSend.serviceId = provider in prices[size] ? prices[size][provider].serviceId : 0;

        setConfirmModal(<ConfirmModal dataToSend={dataToSend} callback={() => {
          dataToSend.senderPhone = '+372' + dataToSend.senderPhone;
          dataToSend.consigneePhone = '+372' + dataToSend.consigneePhone;

          Api('shipments/' + provider, dataToSend, 2).then(res => {
            if (res.uid && res.provider) {
              sessionStorage.setItem('packInfo.' + res.uid, JSON.stringify(dataToSend));
              setPackCode(res.uid);
            } else {
              if (res['violations']) {
                for (let error of res['violations']) {
                  setFieldError(error.propertyPath, error.message);
                }
              } else {
                alert(t('errors.unknown'));
              }
            }
            setConfirmModal(<></>);
            setSubmitting(false);
          });

        }} cancelCallback={() => {
          setSubmitting(false);
          setConfirmModal(<></>);
        }} />);

      }}
    >
      {({ isSubmitting }) => (<Form className={'col-1-2'}>
        <div className={'flex-column bg-gray-100 ph-4 pv-3 gap-4'}>

          <div className={'small normal text-uppercase'}>
            <FontAwesomeIcon icon={faPersonCarryBox} className={'mr-2 m-large-icon largest'} />
            {t('sender_info')}
          </div>

          <label>
            <Field type={'text'} name={'senderName'} placeholder={' '} />
            <span>{t('senderName')}</span>
            <ErrorMessage name={'senderName'} component={'div'} className={'error'} />
          </label>

          <label>
            <Field type={'email'} name={'senderEmail'} placeholder={' '} />
            <span>{t('senderEmail')}</span>
            <ErrorMessage name={'senderEmail'} component={'div'} className={'error'} />
            <small className={'thin light'}>
              {t('package_card_will_sent_to_here')}
            </small>
          </label>

          <label>
                <span className={'pre-icon wide'}>
                  <img src={etIcon} alt={''} />
                  +372
                </span>
            <Field type={isMobile ? 'number' : 'phone'} name={'senderPhone'} placeholder={' '} />
            <span>{t('senderPhone')}</span>
            <ErrorMessage name={'senderPhone'} component={'div'} className={'error'} />
          </label>

          <div className={'small normal text-uppercase'}>
            <FontAwesomeIcon icon={faBoxCircleCheck} className={'mr-2 m-large-icon largest'} />
            {t('receiver_info')}
          </div>

          <div className={'message success'}>
            {t('receiver_pays')}
          </div>

          <label>
            <Field type={'text'} name={'consigneeName'} placeholder={' '} />
            <span>{t('consigneeName')}</span>
            <ErrorMessage name={'consigneeName'} component={'div'} className={'error'} />
          </label>

          <label>
            <Field type={'email'} name={'consigneeEmail'} placeholder={' '} />
            <span>{t('consigneeEmail')}</span>
            <ErrorMessage name={'consigneeEmail'} component={'div'} className={'error'} />
          </label>

          <label>
                <span className={'pre-icon wide'}>
                  <img src={etIcon} alt={'EE'} />
                  +372
                </span>
            <Field type={isMobile ? 'number' : 'phone'} name={'consigneePhone'} placeholder={' '} />
            <span>{t('consigneePhone')}</span>
            <ErrorMessage name={'consigneePhone'} component={'div'} className={'error'} />
          </label>

          <label>
            <FontAwesomeIcon icon={faMagnifyingGlass} className={'pre-icon'} />
            <AutoComplete label={t('boxLocation').replace('[provider]', provider)} options={options} callback={(res: number, text: string) => {
              setBoxLocation(res);
              setBoxText(text);
            }} />
            <ErrorMessage name={'boxLocation'} component={'div'} className={'error'} />
          </label>
        </div>


        <div className={'ph-4 pv-3'}>
          <label>
            <Field type={'checkbox'} name={'agree'} />
            <span dangerouslySetInnerHTML={{ __html: t('agree') }}></span>
            <ErrorMessage name={'agree'} component={'div'} className={'error'} />
          </label>

          <button type={'submit'} disabled={isSubmitting} className={'btn-primary wide mt-4'}>
            {t('continue')}
          </button>
        </div>
      </Form>)}
    </Formik>
    {confirmModal}
  </>);
};
export default PackInfo;

const formatPhone = (phoneNo: number | string) => {
  phoneNo = String(phoneNo).replaceAll(' ', '').replace('.', '').replaceAll(',', '');
  if (phoneNo.substring(0, 4) === '+372') {
    phoneNo = phoneNo.substring(4);
  } else if (phoneNo.substring(0, 3) === '372') {
    phoneNo = phoneNo.substring(3);
  }
  return phoneNo;
};