import { useTranslation } from 'react-i18next';
import PackProvider from '../../components/PackProvider';
import { priceData } from '../../interfaces/PriceData';
//import Url, { Redirect } from '../../helpers/Url';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';

const Provider = ({ size, prices }: { size: string, prices?: priceData[] }) => {
  const { t } = useTranslation('common');
  let arrayPrices:priceData[] = [];

  if (prices) {
    arrayPrices = Object.values(prices);
    arrayPrices.sort((a, b) => a.price - b.price)
  }


  return (
    <>
      <div className={'flex-row mt-8'}>
        <div className={'mr-4 flex-end pack-large ' + size}>
          <img src={'/img/pack/' + size + '_active.svg'} alt={size.toUpperCase()} />
          <span className={'size'}>{size.toUpperCase()}</span>
          <span className={'height'}>{t('height')}</span>
          <span className={'width'}>{t('width')}</span>
          <span className={'depth'}>{t('depth')}</span>
        </div>
        <div className={'col'}>
          <div className={'text-uppercase text-gray-200 small mb-2'}>{t('package_info')}</div>
          <strong>{size.toUpperCase()}</strong> <span className={'thin'}>{t('box.description_' + size)}</span>
          <div className={'thin mt-4 mb-8'}>
            {/*<Url href={process.env.REACT_APP_OSTA_URL + '/index.php?fuseaction=support.page&id=1396'} target={'_blank'}>
              <>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={'mr-1'} /> {t('instructions_for_packing')}
              </>
            </Url>*/}
          </div>
        </div>
      </div>
      {arrayPrices
        ? (
          <>
            <h3 className={'hide-on-mobile'}>{t('choose_provider')}</h3>
            <div className={'flex-row items-3 wide mb-8'}>
              {arrayPrices.map((details) => <PackProvider size={size} providerId={details.provider} details={details} key={details.provider} />)}
            </div>
          </>
        )
        : null
      }
    </>
  );
};
export default Provider;