import Url from '../helpers/Url';
import Currency from '../helpers/Currency';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ProviderIcons } from '../helpers/ProviderIcons';

interface PackProviderProps {
  size: string,
  providerId: string,
  details: {
    price: number,
    height: number,
    width: number,
    length: number,
    weight: number,
  }
}

const PackProvider = ({size, providerId, details}: PackProviderProps) => {
  const { t } = useTranslation('common');
  const { provider } = useParams();

  return (
    <div className={'col max-w-1-3'}>
      <Url href={'/send/' + size + '/' + providerId} className={'btn-provider'}>
        <>
          <div className={'flex-row mobile-row wide flex-center'}>
            <div className={'col strong'}>
              <img src={ProviderIcons[providerId]} alt={providerId} className={providerId} />
              /&nbsp;
              {size.toUpperCase()}
            </div>
            <div className={'text-primary strong'}>{Currency(details['price'])}</div>
          </div>
          <hr className={'mv-3'} />
          <div className={'flex-row mobile-row titles thin'}>
            <div className={'col'}>{t('height')}</div>
            <div className={'col'}>{t('width')}</div>
            <div className={'col'}>{t('depth')}</div>
            <div className={'col'}>{t('max_weight')}</div>
          </div>
          <div className={'flex-row mobile-row sizes'}>
            <div className={'col'}>{details['height']} {t('default_size_unit')}</div>
            <div className={'col'}>{details['width']} {t('default_size_unit')}</div>
            <div className={'col'}>{details['length']} {t('default_size_unit')}</div>
            <div className={'col'}>{details['weight']} {t('default_weight_unit')}</div>
          </div>

          <span className={'btn-primary wide mt-4 mb-2' + (provider === providerId ? '' : ' outline')}>
            {provider === providerId ? <><FontAwesomeIcon icon={faCheck} className={'mr-1'} /> {t('chosen')}</> : t('choose')}
          </span>
        </>
      </Url>
    </div>
  );
};
export default PackProvider;