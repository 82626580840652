import { PackInfo, PackTrackingInfo } from '../../interfaces/PackInfo';
import FormatDate from '../../helpers/FormatDate';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TrackingInfo = ({ trackingInfo }: { trackingInfo: PackTrackingInfo[] | PackInfo[] }) => {
  const { t } = useTranslation('common');

  return (
    <ul className={'delivery'}>
      {trackingInfo.map((info, index) => {
        return <li className={'status-' + (info.dateShipped ? '1' : (info.dateReceived ? '2' : '0'))} key={index}>
          {t('provider.' + info.provider)}
          | {t('status.' + (info.dateShipped ? 'shipped' : (info.dateReceived ? 'delivered' : 'open')))}
          <small>{info.dateShipped || info.dateReceived ? ' (' + FormatDate(info.dateReceived ? info.dateReceived : info.dateShipped) + ')' : null}</small>
        </li>;
      })}
    </ul>

  );
};

export default TrackingInfo;