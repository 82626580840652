import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faBoxCircleCheck, faPersonCarryBox } from '@fortawesome/pro-solid-svg-icons';
import { PackInfo } from '../../interfaces/PackInfo';
import { ProviderIcons } from '../../helpers/ProviderIcons';

const ConfirmModal = ({ dataToSend, callback, cancelCallback }: { dataToSend: PackInfo, callback: Function, cancelCallback: Function }) => {
  const { t } = useTranslation('common');

  return (<div className={'modal show'}>
    <div className={'center'}>
      <h3>{t('check_details')}</h3>

      <div className={'border-box left wide'}>
        <div className={'flex-column gap-2'}>
          <div className={'text-uppercase'}>
            <FontAwesomeIcon icon={faBox} className={'mr-2 m-large-icon largest'} />
            {t('package_info')}
          </div>
          <div>
            <div className={'text-uppercase thin small mb-1'}>{t('box_and_it_size')}</div>
            <div>
              <img src={ProviderIcons[dataToSend.provider]} alt={dataToSend.provider} className={'mr-2 ' + dataToSend.provider} />
              <span className={'text-uppercase'}>
              / {dataToSend.boxSize} {t('package_box')}
              </span>
            </div>
          </div>
        </div>
        <hr className={'light fat mv-6'} />
        <div className={'flex-column'}>
          <div className={'text-uppercase'}>
            <FontAwesomeIcon icon={faPersonCarryBox} className={'mr-2 m-large-icon largest'} />
            {t('sender_info')}
          </div>
          <div>
            <div className={'text-uppercase thin small'}>
              {t('phone_no')}
            </div>
            <div>{dataToSend.senderPhone}</div>
          </div>
          <div>
            <div className={'text-uppercase thin small'}>
              {t('senderEmail')}
            </div>
            <div>
              {dataToSend.senderEmail}
            </div>
          </div>
        </div>
        <hr className={'light fat mv-6'} />
        <div className={'flex-column'}>
          <div className={'text-uppercase'}>
            <FontAwesomeIcon icon={faBoxCircleCheck} className={'mr-2 m-large-icon largest'} />
            {t('receiver_info')}
          </div>
          <div>
            <div className={'text-uppercase thin small'}>
              {t('destination')}
            </div>
            <div>
              {dataToSend.boxName}
            </div>
          </div>
          <div>
            <div className={'text-uppercase thin small'}>
              {t('phone_no')}
            </div>
            <div>{dataToSend.consigneePhone}</div>
          </div>
          <div>
            <div className={'text-uppercase thin small'}>
              {t('consigneeEmail')}
            </div>
            <div>{dataToSend.consigneeEmail}</div>
          </div>
        </div>
      </div>
      <div className={'clear-fix m-5'}></div>

      <div className={'flex sticky-bottom pt-2 pb-8'}>
        <button className={'link thin wide'} onClick={() => {cancelCallback()}}>{t('change_details')}</button>
        <button className={'btn-primary wide'} onClick={() => {callback()}}>{t('form.continue')}</button>
      </div>

    </div>
  </div>);
};
export default ConfirmModal;