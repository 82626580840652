import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import * as Sentry from '@sentry/react';
import XHR from 'i18next-http-backend';
import common_et from './translations/et.json';
import common_en from './translations/en.json';
import common_ru from './translations/ru.json';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const allLanguages = process.env.REACT_APP_LANGUAGES;
let langs: string[] = [];
if (allLanguages) {
  langs = allLanguages.split(',');
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE_FRONTEND,
  integrations: [
  Sentry.reactRouterV6BrowserTracingIntegration({
    useEffect: React.useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes
  }),
  Sentry.replayIntegration()
],
  tracesSampleRate: 1.0,

  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

i18next
  .use(XHR)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: 'et',
    supportedLngs: langs,
    resources: {
      et: { common: common_et },
      en: { common: common_en },
      ru: { common: common_ru },
    },
  })
  .then(() => {});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
