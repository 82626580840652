import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import Api from '../helpers/Api';
import { GoogleMap, InfoBoxF, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faSpinnerThird, faXmark } from '@fortawesome/pro-solid-svg-icons';

const providerColors = {
  omniva: '#FF6600',
  itella: '#005dc0',
  unisend: '#356606',
  venipak: '#9339F2',
};
let defaultCenter = {
  lat: 58.8,
  lng: 25
};

interface location {
  locationId: number
  provider: 'itella' | 'omniva' | 'unisend' | 'venipak'
  name: string
  city?: string
  address?: string
  lat: number
  lng: number
  nearby: number[],
  grouped?: boolean,
  cnt?: number
}

let defaultZoom = 7;

const Map = () => {
  const { t } = useTranslation('common');
  const [locations, setLocations] = useState<location[]>([]);
  const [results, setResults] = useState<location[]>([]);
  const [map, setMap] = useState<google.maps.Map>();
  const [infoBox, setInfobox] = useState<location | null>(null);
  const [lastKeyword, setLastKeyword] = useState('');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ? process.env.REACT_APP_GOOGLE_API_KEY : ''
  });

  useEffect(() => {
    if (!locations.length) {
      Api('shipments/locations').then(res => {
        let filtered = [];
        for (let location of res['data']) {
          if (location['lat'] > 57.4 && location['lat'] < 60 && location['lng'] > 21 && location['lng'] < 28) {
            filtered.push(location);
          }
        }
        setLocations(filtered);
      });
    }

    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        defaultCenter = {
          lat: location.coords.latitude,
          lng: location.coords.longitude
        };
        defaultZoom = location.coords.accuracy < 1000 ? 15 : 12;
        if (map) {
          map.setCenter(new google.maps.LatLng(defaultCenter.lat, defaultCenter.lng));
          map.setZoom(defaultZoom);
        }
      });
    }
  }, [locations.length, map]);

  const mapSearch = (str?: string) => {
    if (!map) {
      return;
    }
    let newResults: location[] = [];
    let groupDistance = 10;
    let bounds: google.maps.LatLngBounds | undefined;
    let fitBounds = false;
    if (map) {
      bounds = map.getBounds();
    }
    if (typeof str === 'undefined') {
      str = lastKeyword;
    } else {
      setLastKeyword(str);
      fitBounds = true;
    }

    for (let location of locations) {
      location.grouped = false;
      location.nearby = [];
      location.cnt = 0;
      if (
        (!str || (location.name && location.name.search(new RegExp(str, 'i')) > -1))
        && (fitBounds || !bounds || bounds.contains(new google.maps.LatLng(location.lat, location.lng)))
      ) {
        newResults.push(location);
      }
    }

    // group if too many results
    let maxNearby = 0;
    let currZoom = map?.getZoom();
    if (!currZoom) {
      currZoom = 10;
    }
    if (newResults.length > 10 && currZoom < 15) {
      if (bounds) {
        groupDistance = distance(bounds.getSouthWest().lat(), bounds.getSouthWest().lng(), bounds.getNorthEast().lat(), bounds.getNorthEast().lng()) / 10;
      }

      for (let i in newResults) {
        newResults[i].nearby = [];
        for (let i2 in newResults) {
          if (newResults[i2] === newResults[i] || newResults[i2].provider !== newResults[i].provider) {
            continue;
          }
          if (distance(newResults[i].lat, newResults[i].lng, newResults[i2].lat, newResults[i2].lng) < groupDistance) {
            newResults[i].nearby.push(parseInt(i2));
          }
        }
        if (maxNearby < newResults[i].nearby.length) {
          maxNearby = newResults[i].nearby.length;
        }
      }

      for (let cnt = maxNearby; cnt > 1; cnt--) {
        for (let i in newResults) {
          if (!newResults[i].grouped && newResults[i].nearby.length === cnt) {
            newResults[i].grouped = true;
            newResults[i].cnt = cnt + 1;
            for (let index of newResults[i].nearby) {
              newResults[index].grouped = true;
            }
          }
        }
      }

      for (let i = newResults.length - 1; i > 0; i--) {
        if (newResults[i].grouped && !newResults[i].cnt) {
          delete newResults[i];
          newResults.splice(i, 1);
        }
      }
    }

    if (fitBounds && newResults.length) {
      if (newResults.length === 1) {
        map.setZoom(16);
        map.setCenter(new google.maps.LatLng(newResults[0].lat, newResults[0].lng));
      } else {
        let newBounds = new google.maps.LatLngBounds();
        for (let result of newResults) {
          newBounds.extend(new google.maps.LatLng(result.lat, result.lng));
        }
        map.fitBounds(newBounds, 100);
      }
    }

    setResults(newResults);
  };

  const closeInfobox = (event?: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
    }
    if (infoBox) {
      setInfobox(null);
    }

    // this is hack against empty infobox - couldn't find better way to fight against google
    for (let box of document.getElementsByClassName('infoBox')) {
      box.remove();
    }
  };


  return (
    <>
      <h3>{t('find-on-map')}</h3>

      <Formik
        initialValues={{
          location: ''
        }}
        onSubmit={(values, { setSubmitting }) => {
          mapSearch(values.location);
          setSubmitting(false);
        }}
      >{({ isSubmitting }) => (
        <Form>
          <div className={'flex-row mobile-row'}>
            <label className={'col'}>
              <Field type={'text'} name={'location'} placeholder={' '} />
              <span>{t('insert_location')}</span>
            </label>

            <div className={'col-1-5'}>
              <button type={'submit'} className={'btn-primary wide'} disabled={isSubmitting}>{t('search')}</button>
            </div>
          </div>

        </Form>
      )}
      </Formik>

      <div className={'map mt-8'}>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: '100%'
            }}
            center={defaultCenter}
            zoom={defaultZoom}
            onLoad={(map) => {
              setMap(map);
            }}
            onIdle={() => {
              mapSearch();
            }}
          >
            <>
              {results.map(result => {
                return result.cnt ? (
                  <MarkerF
                    key={result.lat + result.lng + 'grouped'}
                    position={new google.maps.LatLng(result.lat, result.lng)}
                    icon={{
                      path: google.maps.SymbolPath.CIRCLE,
                      fillColor: providerColors[result.provider],
                      fillOpacity: 1,
                      strokeWeight: 0,
                      scale: 20
                    }}
                    label={{
                      text: '' + result.cnt,
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: '#ffffff'
                    }}
                    onClick={() => {
                      if (map) {
                        let currZoom = map.getZoom();
                        if (currZoom) {
                          map.setZoom(currZoom + 1);
                          map.setCenter(new google.maps.LatLng(result.lat, result.lng));
                        }
                      }
                    }}
                  />
                ) : (
                  <MarkerF
                    key={result.lat + result.lng}
                    position={new google.maps.LatLng(result.lat, result.lng)}
                    icon={{
                      path: faMapPin.icon[4] as string,
                      fillColor: providerColors[result.provider],
                      fillOpacity: 1,
                      scale: 0.06,
                      anchor: new google.maps.Point(160, 500)
                    }}
                    onClick={() => setInfobox(result)}
                  >
                    {infoBox === result ? (
                      <InfoBoxF
                        options={{
                          boxClass: 'infoBox',
                          closeBoxURL: '',
                          pixelOffset: new window.google.maps.Size(-90, -140)
                        }}
                      >
                        <>
                          <div className={'inner'}>
                            <a href={'#close'} className={'close'} onClick={closeInfobox}>
                              <FontAwesomeIcon icon={faXmark} />
                            </a>
                            <strong>{t('provider.' + result.provider)}</strong>
                            <p>{result.name}</p>
                            {result?.address}
                          </div>
                        </>
                      </InfoBoxF>
                    ) : null}
                  </MarkerF>
                );
              })}
            </>
          </GoogleMap>
        ) : (
          <div>
            <FontAwesomeIcon icon={faSpinnerThird} className={'spinner'} />
          </div>)}
      </div>

      {/*{results.map((result, index) => {
        return (
          <div key={index}>
            {result.name}
            <small className={'text-primary ml-4'}>
              {result.lat}
              :
              {result.lng}
            </small>
          </div>
        );
      })}*/}
    </>
  );
};
export default React.memo(Map);

// get distance between 2 coordinates
const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371; // km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

// Converts numeric degrees to radians
const toRad = (val: number) => {
  return val * Math.PI / 180;
};