import { PackInfo } from '../../interfaces/PackInfo';
import '../scss/Pack.scss';
import FormatDate from '../../helpers/FormatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import TrackingInfo from './TrackingInfo';
import { ProviderIcons } from '../../helpers/ProviderIcons';
import { useTranslation } from 'react-i18next';

const Pack = ({ item }: { item: PackInfo }) => {
  const { t } = useTranslation('common');
  const [showTracking, setShowTracking] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  return (<>
    <div className={'pack'}>
      <div onClick={() => setShowDetails(!showDetails)} className={'main'}>
        <div>
          <div>{item.consigneeName}</div>
          {!showDetails && <small className={'thin text-gray-300'}>{item.consigneeEmail}</small>}
        </div>


        <div className={'right'}>
          {(item.dateReceived && (
            <div className={'status delivered'}>{t('status_short.delivered')} <small>{FormatDate(item.dateReceived)}</small></div>)) || (item.dateShipped && (
            <div className={'status shipped'}>{t('status_short.shipped')} <small>{FormatDate(item.dateShipped)}</small></div>)) || (
            <div className={'status open'}>{t('status_short.open')}</div>)}
        </div>
        <div className={'right'}>
          <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} />
        </div>
      </div>


      <div className={'collapse' + (showDetails ? ' show' : '')}>
        <div className={'details'}>
          <img src={ProviderIcons[item.provider]} alt={item.provider} />

          <div className={'thin text-gray-300 mt-2'}>{t('receiver_info')}</div>
          <div>{item.consigneeName}</div>
          <div>{item.consigneeEmail}</div>
          <div>{item.consigneePhone}</div>

          <div className={'thin text-gray-300 mt-2'}>{t('box_and_it_size')}</div>
          <div>{item.locationName}</div>
        </div>

        <div className={'flex-row'}>
          {item.barcode &&
            <button onClick={() => setShowTracking(!showTracking)} className={'btn btn-light'}>{showTracking ? t('track_package_close') : t('track_package')}</button>}
          {item.labelUrl && <a href={item.labelUrl} target={'_blank'} className={'btn btn-light'} rel="noreferrer">{t('open_label')}</a>}
        </div>

        <div className={'collapse' + (showTracking ? ' show' : '')}>
          <div className={'tracking'}><TrackingInfo trackingInfo={[item]} /></div>
        </div>
      </div>

    </div>
  </>);
};
export default Pack;