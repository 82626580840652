import './scss/Header.scss';
import { useTranslation } from 'react-i18next';
import boxes from '../img/boxes.png';
import { ProviderIcons } from '../helpers/ProviderIcons';

const Header = () => {
  const { t } = useTranslation('common');

  return (<header className={'header'}>
    <div className={'container'}>
      <div className={'slogan'}>
        {t('header.slogan')}
        <div className={'logos'}>
          <img src={ProviderIcons['omniva']} alt={'Omniva'} className={'omniva'} />
          <img src={ProviderIcons['itella']} alt={'Itella smartpost'} className={'smartpost'} />
          <img src={ProviderIcons['unisend']} alt={'Unisend'} className={'unisend'} />
          <img src={ProviderIcons['venipak']} alt={'Venipak'} className={'venipak'} />
        </div>
      </div>
      <div className={'boxes'}>
        <img src={boxes} alt={''} />
      </div>
    </div>
  </header>);
};
export default Header;