import Pack from './Pack';
import { PackInfo } from '../../interfaces/PackInfo';
import { ReactNode, useState } from 'react';
import FormatDate from '../../helpers/FormatDate';
import { useTranslation } from 'react-i18next';


const PackList = ({ items }: { items: PackInfo[], packCode?: string, packTrack?: ReactNode }) => {
  const { t } = useTranslation('common');
  const [activeTab, setActiveTab] = useState('');

  let amounts = { total: items.length, open: 0, shipped: 0, delivered: 0 };

  const filteredItems = items.map((item) => {
    item.dayCreated = FormatDate(item.dateCreated, 'date');

    !item.dateShipped && amounts['open']++;
    item.dateShipped && !item.dateReceived && amounts['shipped']++;
    item.dateReceived && amounts['delivered']++;

    if (!activeTab || (activeTab === 'open' && !item.dateShipped) || (activeTab === 'shipped' && item.dateShipped && !item.dateReceived) || (activeTab === 'delivered' && item.dateReceived)) {
      return item;
    }
    return null;
  });

  if (!items.length) {
    return null;
  }

  let lastDate = '';

  return <>
    <div className={'tabs'}>
      <button className={!activeTab ? 'active' : ''} onClick={() => setActiveTab('')}>{t('status_short.all')} <small>({amounts.total})</small></button>
      <button className={activeTab === 'open' ? 'active' : amounts.open ? '' : 'disabled'}
              onClick={() => setActiveTab('open')}>{t('status_short.open')} <small>({amounts.open})</small></button>
      <button className={activeTab === 'shipped' ? 'active' : amounts.shipped ? '' : 'disabled'}
              onClick={() => setActiveTab('shipped')}>{t('status_short.shipped')} <small>({amounts.shipped})</small></button>
      <button className={activeTab === 'delivered' ? 'active' : amounts.delivered ? '' : 'disabled'}
              onClick={() => setActiveTab('delivered')}>{t('status_short.delivered')} <small>({amounts.delivered})</small></button>
    </div>

    {filteredItems.map((item) => {
      return item ? (<div key={item.id}>
        {lastDate !== (lastDate = item.dayCreated || '') && (<div className={'center mt-4 text-gray thin'}>{lastDate}</div>)}
        <Pack item={item} />
      </div>) : null;
    })}

  </>;
};
export default PackList;