import Url from '../helpers/Url';
import LanguageSelector from './LanguageSelector';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import './scss/OstaHeader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLongToLine, faCircleQuestion, faUser } from '@fortawesome/pro-solid-svg-icons';
import Api from '../helpers/Api';

interface login {
  id: number;
  username: string;
  avatar: string;
  notificationCount: number;
  freeItems?: number;
}

const OstaHeader = () => {
  const [loginInfo, setLoginInfo] = useState<login | null>(null);
  const { t } = useTranslation('common');
  const ostaUrl = process.env.REACT_APP_OSTA_URL + (i18next.language === 'et' ? '' : i18next.language + '/');

  useEffect(() => {
    Api('users/me', undefined, 1).then((res) => {
      setLoginInfo(res || null);
    });
  }, []);

  if (!document.location.host.includes('localhost')) {
    document.addEventListener('focus', () => {
      fetch('/?token=' + Math.random()).then((token) => token.json()).then(token => {
        sessionStorage.setItem('userToken', token['token']);
        Api('users/me', undefined, 1, true).then((res) => {
          setLoginInfo(res || null);
        });
      });
    }, { once: true });
  }

  return (
    <>
      <div className={'navbar'}>
        <div className={'inner'}>
          <div className={'left'}>
            <Url href={'/'} className={'logo'}>
              <img src={'/img/logo_animate.svg'} alt={'Osta.ee'} />
            </Url>

            <LanguageSelector />

            <Url href={ostaUrl + 'kkk'} target={'_blank'} className={'hide-on-mobile'}>
              <>
                {t('navbar.faq')}
                <FontAwesomeIcon icon={faCircleQuestion} className={'ml-1 mt-1'} />
              </>
            </Url>

            <Url href={ostaUrl} target={'_blank'} className={'thin hide-on-mobile'}>
              <>
                <FontAwesomeIcon icon={faArrowLeftLongToLine} className={'mr-2 ml-1'} />
                {t('navbar.back_to_osta')}
              </>
            </Url>
          </div>
          <div className={'right'}>
            {loginInfo?.id ? (
              <>
                <Url href={ostaUrl + '?fuseaction=my.main'} target={'_blank'}>
                  <>
                    <img src={loginInfo.avatar} alt={loginInfo.avatar} className={'avatar'} />
                    {loginInfo.username}
                    <span className={'messages'}>{loginInfo.notificationCount > 0 ? loginInfo.notificationCount : null}</span>
                  </>
                </Url>
              </>
            ) : (
              <>
                <Url href={ostaUrl + '?fuseaction=register.user&pp=' + document.location.pathname} target={'_blank'}>
                  <>
                    {t('navbar.register')}
                  </>
                </Url>
                <Url href={ostaUrl + '?fuseaction=member.login&pp=' + document.location.pathname} target={'_blank'}>
                  <>
                    <FontAwesomeIcon icon={faUser} className={'hide-on-xs'} />
                    {t('navbar.login')}
                  </>
                </Url>
              </>
            )}

          </div>
        </div>
      </div>
    </>
  );
};

export default OstaHeader;
