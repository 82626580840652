import { useTranslation } from 'react-i18next';

const FormatDate = (date?: string | undefined, type?: 'date') => {
  const { t } = useTranslation('common');
  let d = date ? new Date(date) : new Date();

  if (type === 'date') {
    return d.getDate() + '. ' + t('month_names.' + d.getMonth()) + ' ' + d.getFullYear();
  }

  return d.toLocaleString();
}
export default FormatDate;